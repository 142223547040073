<template>
  <div class="blog-item">
    <div class="blog-item__publishedAt" v-if="showPublishedAt">{{ publishedAt }}</div>
    <div class="blog-item__mobile">
      <a
        class="blog-item__mobile-title"
        @click="subheadingClick">
          {{ elem.title || elem.attributes.title }}
        </a>
    </div>
    <div class="blog-item__image" @click="subheadingClick">
      <img :src="cover" :alt="cover"/>
    </div>
    <div class="blog-item__content">
      <a class="blog-item__title" @click="titleClick">{{ elem.title || elem.attributes.title }}</a>
      <div class="blog-item__category-subcategory">{{ elem.categoriesSubcategories }}</div>
      <p class="blog-item__subheading" @click="subheadingClick" v-html="elem.subheading || elem.attributes.subheading"/>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import './BlogItem.styl';
  import moment from 'moment'
  export default {
    props: ['elem', 'showPublishedAt'],
    name: 'BlogItem',
    data: () => ({
      dMobile: undefined,
    }),
    computed: {
      author () {
        return this.elem.author || _.get(this.elem, 'attributes.author');
      },
      cover () {
        return _.get(this.elem, 'cover.url') || _.get(this.elem, 'attributes.cover.data.attributes.url')
      },
      categories () {
        return (_.get(this.elem, 'categories') || _.get(this.elem, 'attributes.categories.data') || []);
      },
      subcategories () {
        return (_.get(this.elem, 'subcategories') || _.get(this.elem, 'attributes.subcategories.data') || [])
      },
      publishedAt () {
        return moment(this.elem.publishedAt || this.elem.attributes.publishedAt).format('MMM DD, YYYY')
      },
    },
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile');
    },
    methods: {
      subheadingClick () {
        if (this.dMobile) {
          this.$router.push('/theflyreport/article/' + this.elem.article_path);
        }
      },
      titleClick () {
        if (!this.dMobile) {
          this.$router.push('/theflyreport/article/' + this.elem.article_path);
        }
      },
    },
  };
</script>
