<template>
  <div class="blog-feed js-acc-item">
    <div class="blog-feed__newsfeed">
      <div class="blog-feed__newsfeed-date">Newsfeed: {{ new Date() | formatBlogDate }}</div>
      <div class="blog-feed__newsfeed-icon">
        <SvgIcon name="up-arrow"/>
      </div>
    </div>
    <div class="js-acc-content">
      <div class="blog-feed__list js-blog-scrollbar">
        <vue-scroll :ops="ops">
          <div  v-for="word of feed" :key="word.id">
            <div class="blog-feed-word">{{ word.title }}</div>
            <div
            class="blog-feed-item"
            :class="[{'blog-feed-item--read' : item.read}]"
            v-for="item of word.posts.results"
            :key="item.id"
            >
            <div class="blog-feed-item__wrap">
              <div class="blog-feed-item__time">{{item.publishedAt | formatTime}}</div>
              <a class="blog-feed-item__title" :href="item.link" target="_blank">{{item.title}}</a>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './BlogFeed.styl';

  export default {
    name: 'BlogFeed',
    data: () => ({
      updateInterval: null,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'var(--scroll-bar-background)',
        },
      },
    }),
    computed: {
      feed () {
        return this.$store.state.blog.feed.filter(({posts}) => posts.results.length > 0);
      },
    },
    mounted () {
      const blogFeed = document.querySelector('.blog-feed__newsfeed');
      const html = document.querySelector('html');

      if (blogFeed) {
        this.updateInterval = setInterval(() => {
          this.$store.dispatch('getFeed')
        }, 60000)

        if (!html.classList.contains('d-mobile') && this.$route.path !== '/theflyreport/search') {
          document.querySelector('.blog-feed__newsfeed').classList.add('active');
          document.querySelector('.blog-feed__newsfeed').closest('.js-acc-item').querySelector('.js-acc-content').classList.add('active');
        }

        blogFeed.addEventListener('click', (event) => {
          event.currentTarget.classList.toggle('active');
          const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-acc-content');
          item.classList.toggle('active');
        });
      }
    },
    unmounted () {
      clearInterval(this.updateInterval)
    },
    components: {
      SvgIcon,
    },
  };
</script>
